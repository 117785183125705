import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import queryString from "query-string";
import { useAuth } from "../../hooks/useAuth";
import { Button } from "react-bootstrap";
import CashIcon from "mdi-react/CashIcon";
import { useEffectOnce } from "../../utils/hooks";

// This function handles the tax payment process with React Query, triggered on demand
export const useTaxPayment = (backendUrl, taxDetails, filterParams) => {
  const navigate = useNavigate();

  // Mutation for the API call
  const taxPayMutation = useMutation(
    async data => {
      const filterBy = { Description: `${taxDetails.value} Payable Clearing Account` };
      const response = await fetch(
        `${backendUrl}/api/nonjournal/check-for?${queryString.stringify(filterBy)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to process tax payment");
      }

      const responseData = await response.json();
      if (responseData.notReconciledCount > 0) {
        throw new Error("Please Remit in clearing account to continue");
      }

      return responseData;
    },
    {
      onSuccess: (responseData, variables) => {
        navigate("/expenses-entry", {
          state: {
            expenses: [
              {
                ...variables.account,
                Amount: variables.balanceOfEndingBalance,
              },
            ],
          },
        });
        //  toast.success("Tax payment processed successfully");
      },
      onError: error => {
        toast.error(`Error processing payment: ${error.message}`);
      },
    }
  );

  // The main handler function - manually trigger the process
  const handleTaxPay = async data => {
    // Manual eligibility check
    if (filterParams.vatDirection) {
      toast.error(`Cannot Pay only ${filterParams?.vatDirection}: filter by All`);
      return;
    }

    // If eligible, proceed with the mutation
    taxPayMutation.mutate(data);
  };

  return {
    handleTaxPay,
    isLoading: taxPayMutation.isLoading,
    isError: taxPayMutation.isError,
    error: taxPayMutation.error,
    isSuccess: taxPayMutation.isSuccess,
    reset: taxPayMutation.reset,
  };
};

// Example usage in a component
const TaxPayment = ({ taxDetails, filterParams, data }) => {
  const { backendUrl } = useAuth();
  const { handleTaxPay, isLoading, isError, error } = useTaxPayment(
    backendUrl,
    taxDetails,
    filterParams
  );

  return (
    <div>
      {isLoading && <div className="spinner">Loading...</div>}
      <Button onClick={() => handleTaxPay(data)} disabled={isLoading} className="pay-button">
        <CashIcon /> {isLoading ? "Processing..." : "Pay Tax"}
      </Button>
    </div>
  );
};

export default TaxPayment;
