import { Fragment, useState, useEffect } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import "../assets/scss/NavBar.scss";
import { HamburgerIcon, HomeIcon, Logo, RequisitionNavControllerIcon } from "./Icons";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import { Button, Dropdown, Image, Modal, Spinner } from "react-bootstrap";
import photoUrl from "./../assets/images/profile.png";
import { Link, NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import {
  appSettings,
  backendApis,
  useReportsNavItems,
  salesUserReportNavItems,
  storeUserReportNavItems,
  wareHouseChildren,
  vendorManagement,
  collabOptions,
} from "../config";
import Avatar from "./utils/Avatar";
import {
  useIsAdmin,
  useIsCashier,
  useIsGovernmentInvexERP,
  useIsMaintenance,
  useIsOperations,
  useIsSalesManager,
  useOnClickOutside,
  useResolveRootRoles,
  useToggle,
  useIsOutGrower,
} from "../utils/hooks";
import { useRef } from "react";
import { useMemo } from "react";
import { toast } from "react-toastify";
import UpdateProfileModal from "./UpdateProfileModal";
import { isEmpty, truncate } from "lodash";
import { lowerCase } from "lodash";
import AccountTransferModal from "./SalesAndInvoicing/AccountTransferModal";
import GeneralSettingsModal from "./GeneralSettingsModal";
import RolesPriviledgesModal, { RootRolesPriviledgesModal } from "./RolesPriviledgesModal";
import ModalLoader from "./utils/ModalLoader";
import { services } from "../config";
import CementModals from "./Cement/CementModals";
import eventBus from "../utils/EventBus";
import { useStoreActions, useStoreState } from "easy-peasy";
import { HRFrontEndUrl, IS_HR, HRBackEndUrl, setCookies } from "../config";
import { maintanceChildren } from "../config";
import QuickLinkComponent from "./QuickLinkComponent";
import { toLower } from "lodash";
import ServiceSettingsModal from "./ServiceSettingsModal";
import { Notification } from "./Notification";
import { useCacheBuster } from "react-cache-buster";
import VerifyEmailModal from "./VerifyEmailModal";
import UpdateEmailModal from "./UpdateEmailModal";
import { useQuery } from "react-query";
import { fetchActionsUtil } from "../utils/helpers";
import { UserLginModal } from "./LoginDialog";
import Cookies from "universal-cookie";
import { HoverDropDown, OverflowDropdown } from "./Component";
import GlobalItemSearchModal from "./modals/GlobalItemSearchModal";
import RequisitionNavBar from "./RequisitionNavBar";
import CreateTicket from "./modals/CreateTicket";
import InvoicingNavBar from "./InvoicingNavBar";

function UserProfilePics({ children }) {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column">{children}</div>
  );
}

export default function NavBar() {
  const { checkCacheStatus } = useCacheBuster();
  const location = useLocation();
  const {
    login,
    logout,
    setUser,
    backendUrl,
    user: authUser,
    isCement,
    isTransportAndLogistics,
    isIronRod,
    isExpired,
  } = useAuth();
  const cookies = new Cookies();
  const [smShow, setSmShow] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [navOpen, setNavOpen, toggleNavOpen] = useToggle(false);
  const navHolder = useRef();
  const isAdmin = useIsAdmin();
  const isCashier = useIsCashier();
  const isOutGrower = useIsOutGrower();
  const isSalesManager = useIsSalesManager();
  const isOperations = useIsOperations();
  const isMaintenance = useIsMaintenance();
  const isGovernmentInvexERP = useIsGovernmentInvexERP();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAccountTransferOpen, setIsAccountTransferOpen] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [showGeneralSettingsModal, setShowGeneralSettingsModal] = useState(false);
  const [showRolesPriviledgesModal, setShowRolesPriviledgesModal] = useState(false);
  const [showRootRolesPriviledgesModal, setShowRootRolesPriviledgesModal] = useState(false);
  const [showServiceSettingsModal, setShowServiceSettingsModal] = useState(false);
  const generalSettings = useStoreState(state => state.generalSettings);
  const rootRoles = useStoreState(state => state.rootRoles);

  const savedStaffRoles = useStoreState(state => state.savedStaffRoles);
  const savedDeptRoles = useStoreState(state => state.savedDeptRoles);
  const isAccountExpired = useStoreState(state => state.isAccountExpired);
  const reportsNavItems = useReportsNavItems();

  const { isAdminPrivilegedToSeeSettings, isPrivileged } = useResolveRootRoles();

  const [showSwitchUser, setShowSwitchUser] = useState(false);
  // console.log("authUser => ", authUser);
  // whether to switch company

  const {
    // backendUrl,
    user: { Staff_ID, sound },
  } = useAuth();

  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const [showUpdateEmail, setShowUpdateEmail] = useState(false);
  const [showGlobalItemSearchModal, setShowGlobalItemSearchModal] = useState(false);
  const [showCreateTicket, setShowCreateTicket] = useState(false);
  // const [isShaking, setIsShaking] = useState(false);

  const switchCompanyQueryParam = searchParams.get("switchCompany");
  useEffect(() => {
    if (switchCompanyQueryParam && authUser.company !== switchCompanyQueryParam) {
      const foundCompany = backendApis.find(el => el.name === switchCompanyQueryParam);
      console.log("foundCompany", foundCompany);
      if (foundCompany) switchCompany(foundCompany);
    }
  }, [switchCompanyQueryParam]);

  const { data } = useQuery(["SET-UP"], () =>
    fetchActionsUtil(`${backendUrl}/api/auth/set-up`, "GET")
  );

  const logoutUser = async options => {
    try {
      setSmShow(true);
      const res = await fetch(`${backendUrl}/api/auth/logout`, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        body: JSON.stringify({ Staff_ID: authUser.Staff_ID, ...options }),
        credentials: "include",
      });

      if (res.ok) {
        logout();
      } else {
        toast.error("Unable to perform action: Please try again");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSmShow(false);
    }
  };

  const handleKeyDown = event => {
    // event.preventDefault();
    let charCode = String.fromCharCode(event.which).toLowerCase();
    /* if ((event.ctrlKey || event.metaKey) && charCode === "l") {
      alert("CTRL+S Pressed");
    } else if ((event.ctrlKey || event.metaKey) && charCode === "c") {
      alert("CTRL+C Pressed");
    } else if ((event.ctrlKey || event.metaKey) && charCode === "v") {
      alert("CTRL+V Pressed");
    } */

    if (event.ctrlKey || event.metaKey) {
      switch (charCode) {
        case "l":
          logoutUser();
          break;
        case "m":
          setShowServiceSettingsModal(true);
          break;
        case "q":
          setShowRootRolesPriviledgesModal(true);
          break;
        case "g":
          event.preventDefault();
          setShowGlobalItemSearchModal(true);
          break;
        default:
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown, true);
    return () => window.removeEventListener("keydown", handleKeyDown, true);
  }, []);

  useEffect(() => {
    setNavOpen(false);
  }, [location]);

  useOnClickOutside(navHolder, () => setNavOpen(false));

  const openAccountTransfer = () => {
    setIsAccountTransferOpen(true);
  };

  const storeUserNav = useMemo(
    () => [
      {
        name: "Tools",
        childRoutes: [
          ...(appSettings.isBarrelsAndCrates
            ? [
                {
                  name: "Post Transaction",
                  to: "sales-and-invoicing/create-invoice",
                },
                {
                  name: "Customers",
                  to: "sales-and-invoicing/customer-list",
                },
                {
                  name: "Subscribers",
                  to: "sales-and-invoicing/subscriber-list",
                },
                {
                  name: "Inquirers",
                  to: "sales-and-invoicing/enquire-list",
                },
              ]
            : []),
          {
            name: "Item Issuer",
            to: "inventory-for-sales/item-issuer",
          },
          {
            name: "Receive Inventory",
            to: "receive-inventory",
          },
          {
            name: `Create Proforma Invoice`,
            to: "sales-and-invoicing/create-proforma-invoice",
          },
          ...(appSettings?.isMedbury
            ? [
                {
                  name: `Create Proforma Invoice Without Approval`,
                  to: "sales-and-invoicing/create-proforma-invoice-without-approval",
                },
              ]
            : []),
          {
            name: "Item Conversion",
            to: "inventory-for-sales/item-conversion",
          },
          {
            name: "Stock Count",
            to: "inventory-for-sales/stock-counts",
          },
        ],
      },
      {
        name: "Reports",
        to: "reports",
        childRoutes: [...storeUserReportNavItems],
      },

      {
        name: "Warehouse",
        to: "warehouse",
        childRoutes: [...wareHouseChildren],
      },
      {
        name: "Supply Center",
        to: "supply-center",
      },
      {
        name: "Supply Analysis",
        to: "supply-analysis",
      },
      {
        name: "Purchase Order",
        to: "PO",
      },
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Request and Approvals",
            to: "request",
          },
          {
            name: "Fill a Form",
            to: "fill-a-form",
          },
        ],
      },
      ...(appSettings.isHydroferric
        ? [
            {
              name: "Create Bill",
              to: "vendor/create-vendors-invoice-only",
            },
          ]
        : []),
      {
        name: "Human Resources",
        HR: true,
      },
      collabOptions(),
    ],
    []
  );

  const salesUserNav = useMemo(
    () => [
      {
        name: "Tools",
        childRoutes: [
          {
            name: "Post Transaction",
            to: "sales-and-invoicing/create-invoice",
          },
          {
            name: "Customers",
            to: "sales-and-invoicing/customer-list",
          },
          {
            name: "Subscribers",
            to: "sales-and-invoicing/subscriber-list",
          },

          {
            name: `Create Proforma Invoice`,
            to: "sales-and-invoicing/create-proforma-invoice",
          },
          {
            name: `Pre-order`,
            to: "sales-and-invoicing/preorder-invoice",
          },

          ...(appSettings?.isMedbury
            ? [
                {
                  name: `Create Proforma Invoice Without Approval`,
                  to: "sales-and-invoicing/create-proforma-invoice-without-approval",
                },
              ]
            : []),
          {
            name: "Supply Center",
            to: "supply-center",
          },
          {
            name: "Supply Analysis",
            to: "supply-analysis",
          },
          ...(!appSettings.isBatchStandard
            ? [
                {
                  name: "Receive Payments",
                  to: "post-payments",
                },
              ]
            : []),
          ...(isSalesManager
            ? [
                {
                  name: "Approve Pending Invoice",
                  to: "approval",
                },
                {
                  name: "Pending Online Orders",
                  to: "approval-store",
                },
                {
                  name: "Pending Loading Order",
                  to: "approval-pending-loading-order",
                },
                {
                  name: "Product in Transit",
                  to: "approval-product-on-transit",
                },
              ]
            : []),
          {
            name: "Item Conversion",
            to: "inventory-for-sales/item-conversion",
          },
          {
            name: "Delete Invoice",
            to: "sales-and-invoicing/delete-invoice",
          },
          {
            name: "Stock Count",
            to: "inventory-for-sales/stock-counts",
          },
        ],
      },
      {
        name: "Reports",
        to: "reports",
        childRoutes: [
          ...salesUserReportNavItems.filter(
            el => (isCement ? el.isCementNav : false) || typeof el.isCementNav === "undefined"
          ),
        ],
      },
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Request and Approvals",
            to: "request",
          },
          {
            name: "Fill a Form",
            to: "fill-a-form",
          },
        ],
      },
      ...(appSettings.isHydroferric
        ? [
            {
              name: "Create Bill",
              to: "vendor/create-vendors-invoice-only",
            },
          ]
        : []),
      {
        name: "Human Resources",
        HR: true,
      },
      collabOptions(),
      {
        name: `Switch User`,
        switchUser: true,
      },
    ],
    [, isCement, isSalesManager]
  );

  // Admin
  const items = useMemo(
    () => [
      /*{
        name: "Files",
        childRoutes: [
          {
            name: "Logout",
            shortcut: "Ctrl+L",
            onClick: () => logout(),
          },
        ],
      },*/

      {
        name: "Quick Links",
        childRoutes: [
          {
            name: "Customers",
            to: "sales-and-invoicing/customer-list",
          },
          {
            name: "Subscribers",
            to: "sales-and-invoicing/subscriber-list",
          },
          {
            name: "Sales and Invoicing",
            to: "sales-and-invoicing/invoice-list",
          },
          {
            name: "Make Invoice",
            to: "sales-and-invoicing/create-invoice",
          },
          {
            name: "POS",
            to: "sales-and-invoicing/point-of-sales",
          },
          {
            name: `Pre-order`,
            to: "sales-and-invoicing/preorder-invoice",
          },
          {
            name: `Loading Order`,
            to: "loading-order",
          },
          {
            name: "Sales Inventory",
            to: "inventory-for-sales/inventory-entry",
          },
          {
            name: "Item Issuer",
            to: "inventory-for-sales/item-issuer",
          },

          {
            name: "Global Item Search",
            to: "",
          },

          // {
          //   name: "Business Development/CRM",
          //   to: "business-development-crm",
          // },

          /*  {
            name: "Warehouse Inventory",
            to: "warehouse-inventory/inventory-list",
          }, */

          {
            name: "Supply Center",
            to: "supply-center",
          },
          {
            name: "Supply Analysis",
            to: "supply-analysis",
          },
          {
            name: "Delivery",
            to: "delivery",
          },
          {
            name: "GL Opening Balance",
            to: "journal-modification",
          },
          {
            name: "Sales Rep",
            to: "sales-rep/employees",
          },
          ...(isIronRod
            ? [
                ...(isAdmin || isCashier
                  ? [
                      {
                        name: "Approve Pending Invoice",
                        to: "approval",
                      },
                      ...(generalSettings.store
                        ? [
                            {
                              name: "Pending Online Orders",
                              to: "approval-store",
                            },
                            {
                              name: "Pending Loading Order",
                              to: "approval-pending-loading-order",
                            },
                            {
                              name: "Product in Transit",
                              to: "approval-product-on-transit",
                            },
                            ...(generalSettings?.customerCanEditPriceInCart
                              ? [
                                  {
                                    name: "Pending Online Quotes",
                                    to: "approval-store-quotation",
                                  },
                                ]
                              : []),
                            {
                              name: "Pending Payments from Online Store",
                              to: "approval-store-pending-payment",
                            },
                          ]
                        : []),
                      {
                        name: "Approvals (Vendor Payment, Expenses, Journal etc)",
                        to: "approval-action",
                      },
                    ]
                  : []),
                {
                  name: "Approvals (Vendor Payment, Expenses, Journal etc)",
                  to: "approval-action",
                },
                {
                  name: "Approve Journal Entry",
                  to: "approve-journal-entry",
                },
              ]
            : []),
          ...(isCement
            ? [
                {
                  name: "Raise LPO (Setup ATC)",
                  onClick: () => eventBus.dispatch("showSetupAtcModal", true),
                },
                {
                  name: "Load ATC",
                  to: "load-atc",
                },
                ...(isAdmin
                  ? [
                      {
                        name: "Price Settings",
                        to: "price-settings",
                      },
                      {
                        name: "Create Region",
                        onClick: () => eventBus.dispatch("showCreateRegionModal", true),
                      },
                      {
                        name: "Create Plant",
                        onClick: () => eventBus.dispatch("showCreatePlantModal", true),
                      },
                      {
                        name: "Create Depot",
                        onClick: () => eventBus.dispatch("showCreateDepotModal", true),
                      },
                      {
                        name: "Create Product or Service Category",
                        onClick: () => eventBus.dispatch("showCreateCategoryModal", true),
                      },
                      {
                        name: "Create Sub Category",
                        onClick: () => eventBus.dispatch("showCreateSubCategoryModal", true),
                      },
                    ]
                  : []),
              ]
            : []),
          ...(isTransportAndLogistics
            ? [
                {
                  name: "Driver Salary Center",
                  to: "driver-salary",
                },
              ]
            : []),
          {
            name: "Receive Inventory",
            to: "receive-inventory",
          },
          {
            name: "Expenses Entry",
            to: "expenses-entry-v2",
          },
          isAdmin || !(appSettings.isGigc && isCashier)
            ? {
                name: "Receive Payments",
                to: "post-payments",
              }
            : {},
          {
            name: "Plan Sign Up",
            to: "plan-sign-up",
          },
          {
            name: "Reconcilation",
            to: "reconcilation/customer-ledger/Cash",
          },
          {
            name: "Due Payments",
            to: "sales-and-invoicing/due-invoices",
          },
          {
            name: "Business Development/CRM",
            to: "business-development-crm/customer-list",
          },
          {
            name: "Email Campaign",
            to: "send-customers-email",
          },

          {
            name: "Document Numbering",
            to: "document-number/system",
          },
          {
            name: "Global Item Search",
            onClick: () => setShowGlobalItemSearchModal(true),
          },

          ...(generalSettings.Item_Type === "Service"
            ? [
                {
                  name: "Operations",
                  to: "operations/jobs/",
                },
                {
                  name: "QHSE",
                  to: "qhse/jobs/",
                },
              ]
            : []),

          ...(generalSettings.enableDrumAccount
            ? [
                {
                  name: "Drums Account",
                  grandchildRoutes: [
                    {
                      name: "Update Drum Account",
                      to: "drums/update-drum-account",
                    },
                    {
                      name: "Drum Account Sheet",
                      to: "drums/drum-account-sheet",
                    },
                  ],
                },
              ]
            : []),

          {
            name: "System Companies",
            to: "eb-companies/eb-company-list",
          },
          {
            name: "Invoice Dashboard",
            to: "invoices",
          },
          {
            name: "Scheduled Transactions",
            to: "scheduled-transactions",
          },
          {
            name: "Logistics",
            grandchildRoutes: [
              {
                name: "Loading Order",
                to: "loading-order",
              },
              {
                name: "Create Loading Order",
                to: "sales-and-invoicing/create-loading-order",
              },
              {
                name: "Pending Loading Order",
                to: "approval-pending-loading-order",
              },
              {
                name: "Product in Transit",
                to: "approval-product-on-transit",
              },
            ],
          },
          collabOptions({ childRoutesName: "grandchildRoutes" }),
        ],
      },
      {
        name: "Tools",
        childRoutes: [
          ...(isAdmin
            ? [
                {
                  name: "Return Invoice Item",
                  to: "sales-and-invoicing/return-invoice-item",
                },
                {
                  name: "Replace Invoice Item",
                  to: "sales-and-invoicing/replace-invoice-item",
                },
                {
                  name: "Edit Invoice Payment",
                  to: "sales-and-invoicing/replace-payment",
                } /*  {
                  name: "Edit Invoice",
                  to: "sales-and-invoicing/edit-invoice",
                }, */,
                {
                  name: "Delete Invoice",
                  to: "sales-and-invoicing/delete-invoice",
                },
                {
                  name: "Delete Payment",
                  to: "sales-and-invoicing/delete-payment",
                },
                {
                  name: "Create Proforma Invoice",
                  to: "sales-and-invoicing/create-proforma-invoice",
                },
                {
                  name: "Create Loading Order",
                  to: "sales-and-invoicing/create-loading-order",
                },
                {
                  name: "Create Quotation",
                  to: "sales-and-invoicing/create-quotation",
                },
                ...(appSettings?.isMedbury
                  ? [
                      {
                        name: "Create Proforma Invoice Without Approval",
                        to: "sales-and-invoicing/create-proforma-invoice-without-approval",
                      },
                    ]
                  : []),
                {
                  name: "Credit/Debit Memo",
                  to: "credit-debit-memo",
                },
                {
                  name: "Item Conversion",
                  to: "inventory-for-sales/item-conversion",
                },
                {
                  name: "Refund Payment",
                  to: "refund-payment",
                },
                {
                  name: "Customer Reimbursement",
                  to: "reimbursement",
                },
                {
                  name: "Analytics",
                  to: "analytics",
                },
                {
                  name: "Document Number",
                  to: "document-number/system",
                },
              ]
            : [
                {
                  name: "Credit/Debit Memo",
                  to: "credit-debit-memo",
                },
                {
                  name: "Return Invoice Item",
                  to: "sales-and-invoicing/return-invoice-item",
                },
                {
                  name: "Create Proforma Invoice",
                  to: "sales-and-invoicing/create-proforma-invoice",
                },
                {
                  name: "Create Quotation",
                  to: "sales-and-invoicing/create-quotation",
                },
                ...(appSettings?.isMedbury
                  ? [
                      {
                        name: `Create Proforma Invoice Without Approval`,
                        to: "sales-and-invoicing/create-proforma-invoice-without-approval",
                      },
                    ]
                  : []),
              ]),
        ],
      },
      {
        name: "Warehouse",
        to: "warehouse",
        childRoutes: [...wareHouseChildren],
      },
      {
        name: "Procurement",
        childRoutes: [
          ...vendorManagement.filter(
            el => (isCement ? el.isCementNav : false) || typeof el.isCementNav === "undefined"
          ),
        ],
      },
      {
        name: "Reports",
        to: "reports",
        childRoutes: [...reportsNavItems],
      },
      {
        name: "Production",
        childRoutes: [
          {
            name: "Plants",
            to: "Production/plants",
          },

          {
            name: "Bill of Materials",
            to: "production/temlates",
          },
        ],
      },
      ...(generalSettings.Item_Type === "Service"
        ? [
            {
              name: "Maintenance",
              childRoutes: [...maintanceChildren],
            },
          ]
        : []),
      {
        name: "Account Setup",
        childRoutes: [
          {
            name: "Chart of Account",
            to: "account-setup/chart-of-account",
          },
          {
            name: "Journal",
            to: "journal-entry",
          },
        ],
      },
      {
        name: "Banking",
        childRoutes: [
          {
            name: "Banks",
            to: "banks",
          },
          {
            name: "Bank Account Analysis",
            to: "bank-account-analysis",
          },
          {
            name: "Cash Transfer and Lodgement",
            onClick: () => openAccountTransfer(),
          },
        ],
      },
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Forms",
            to: "forms/company",
          },
          {
            name: "Request and Approvals",
            to: "request",
          },
          ...(isAdmin
            ? [
                {
                  name: "Fund Request Approval",
                  to:
                    "fund-request-approval?page=1&limit=40&q=&status=&type=Fund+Request&sortByDate=dateModified&FormID=&showAll=true",
                },
                {
                  name: "System Forms",
                  to: "system-form",
                },
              ]
            : []),
          {
            name: "Fill a Form",
            to: "fill-a-form",
          },
        ],
      },
      ...(!isAdmin && !isEmpty(savedStaffRoles)
        ? [
            {
              name: "Human Resources",
              HR: true,
            },
            collabOptions({ childRoutesName: "grandchildRoutes" }),
          ]
        : []),

      /* {
        name: <DotsHorizontalIcon style={{ color: "#94A3B8" }} />,
      }, */
    ],
    [
      //  authUser?.company,
      generalSettings.enableDrumAccount,
      isCement,
      isIronRod,
      isCashier,
      isAdmin,
      isTransportAndLogistics,
      generalSettings.Item_Type,
      reportsNavItems,
    ]
  );

  const operationsNavItems = useMemo(
    () => [
      ...(authUser.AccessLavel === "High"
        ? [
            {
              name: "Quick Links",
              childRoutes: [
                {
                  name: "Operations",
                  to: "operations/jobs/",
                },
                {
                  name: "Logistics",
                  grandchildRoutes: [
                    {
                      name: "Loading Order",
                      to: "loading-order",
                    },
                    /*  {
                      name: "Create Loading Order",
                      to: "sales-and-invoicing/create-loading-order",
                    }, */
                    {
                      name: "Pending Loading Order",
                      to: "approval-pending-loading-order",
                    },
                    {
                      name: "Product in Transit",
                      to: "approval-product-on-transit",
                    },
                  ],
                },
              ],
            },
            {
              name: "Maintenance",
              childRoutes: [...maintanceChildren],
            },
            {
              name: "Production",
              childRoutes: [
                {
                  name: "Plants",
                  to: "Production/plants",
                },

                {
                  name: "Bill of Materials",
                  to: "production/temlates",
                },
              ],
            },
            {
              name: "Warehouse",
              to: "warehouse",
              childRoutes: [...wareHouseChildren],
            },
          ]
        : []),
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Request and Approvals",
            to: "request",
          },
          {
            name: "Fill a Form",
            to: "fill-a-form",
          },
        ],
      },
      ...(appSettings.isHydroferric
        ? [
            {
              name: "Create Bill",
              to: "vendor/create-vendors-invoice-only",
            },
          ]
        : []),
      {
        name: "Human Resources",
        HR: true,
      },
      collabOptions(),
    ],
    [authUser]
  );

  const qhseNavItems = useMemo(
    () => [
      {
        name: "Quick Links",
        childRoutes: [
          {
            name: "QHSE",
            to: "qhse/jobs/",
          },
          {
            name: "Approve Inbound PO",
            to: "qhse/warehouse-receive-assurance",
          },
          {
            name: "C of A",
            to: "qhse/approval-action?Type=C of A",
          },
        ],
      },
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Forms",
            to: "forms/company",
          },
          {
            name: "Request and Approvals",
            to: "request",
          },
          {
            name: "Fill a Form",
            to: "fill-a-form",
          },
        ],
      },
      ...(appSettings.isHydroferric
        ? [
            {
              name: "Create Bill",
              to: "vendor/create-vendors-invoice-only",
            },
          ]
        : []),
      {
        name: "Human Resources",
        HR: true,
      },
      collabOptions(),
    ],
    []
  );

  const procurementNavItems = useMemo(
    () => [
      {
        name: "Sales Inventory",
        to: "inventory-for-sales/inventory-entry",
      },
      {
        name: "Warehouse",
        to: "warehouse",
        childRoutes: [...wareHouseChildren],
      },
      {
        name: "Vendor Management",
        childRoutes: [
          ...vendorManagement.filter(
            el => (isCement ? el.isCementNav : false) || typeof el.isCementNav === "undefined"
          ),
        ],
      },
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Request and Approvals",
            to: "request",
          },
          {
            name: "Fill a Form",
            to: "fill-a-form",
          },
        ],
      },
      ...(appSettings.isHydroferric
        ? [
            {
              name: "Create Bill",
              to: "vendor/create-vendors-invoice-only",
            },
          ]
        : []),
      {
        name: "Human Resources",
        HR: true,
      },
      collabOptions(),
    ],
    []
  );

  const buisinessDevelopmentNavItems = useMemo(
    () => [
      {
        name: "Business Development/CRM",
        to: "business-development-crm",
      },
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Request and Approvals",
            to: "request",
          },
          {
            name: "Fill a Form",
            to: "fill-a-form",
          },
        ],
      },
      ...(appSettings.isHydroferric
        ? [
            {
              name: "Create Bill",
              to: "vendor/create-vendors-invoice-only",
            },
          ]
        : []),
      {
        name: "Quick Links",
        childRoutes: [
          {
            name: "Logistics",
            grandchildRoutes: [
              {
                name: "Loading Order",
                to: "loading-order",
              },
              /* {
                name: "Create Loading Order",
                to: "sales-and-invoicing/create-loading-order",
              }, */
              {
                name: "Pending Loading Order",
                to: "approval-pending-loading-order",
              },
              {
                name: "Product in Transit",
                to: "approval-product-on-transit",
              },
            ],
          },
        ],
      },
      {
        name: "Human Resources",
        HR: true,
      },
      collabOptions(),
    ],
    []
  );

  const documentControlNavItems = useMemo(
    () => [
      {
        name: "Document Numbering",
        to: "document-number/system",
      },

      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Forms",
            to: "forms/company",
          },
          {
            name: "Request and Approvals",
            to: "request",
          },
          {
            name: "Fill a Form",
            to: "fill-a-form",
          },
        ],
      },
      ...(appSettings.isHydroferric
        ? [
            {
              name: "Create Bill",
              to: "vendor/create-vendors-invoice-only",
            },
          ]
        : []),
      {
        name: "QHSE",
        to: "qhse/jobs/",
      },
      {
        name: "Human Resources",
        HR: true,
      },
      collabOptions(),
    ],
    []
  );

  // Cashier
  const cashierNavItems = useMemo(
    () => [
      {
        name: "Tools",
        childRoutes: [
          ...(isAdmin
            ? [
                {
                  name: "Return Invoice Item",
                  to: "sales-and-invoicing/return-invoice-item",
                },
                {
                  name: "Replace Invoice Item",
                  to: "sales-and-invoicing/replace-invoice-item",
                },
                {
                  name: "Edit Invoice Payment",
                  to: "sales-and-invoicing/replace-payment",
                },
                {
                  name: "Delete Invoice",
                  to: "sales-and-invoicing/delete-invoice",
                },
                {
                  name: "Delete Payment",
                  to: "sales-and-invoicing/delete-payment",
                },
                {
                  name: "Create Proforma Invoice",
                  to: "sales-and-invoicing/create-proforma-invoice",
                },
                {
                  name: "Create Quotation",
                  to: "sales-and-invoicing/create-quotation",
                },
                {
                  name: "Credit/Debit Memo",
                  to: "credit-debit-memo",
                },
                {
                  name: "Item Conversion",
                  to: "inventory-for-sales/item-conversion",
                },
                {
                  name: "Refund Payment",
                  to: "refund-payment",
                },
                {
                  name: "Analytics",
                  to: "analytics",
                },
              ]
            : [
                {
                  name: "Credit/Debit Memo",
                  to: "credit-debit-memo",
                },
                {
                  name: "Edit Invoice Payment",
                  to: "sales-and-invoicing/replace-payment",
                },
                {
                  name: "Return Invoice Item",
                  to: "sales-and-invoicing/return-invoice-item",
                },
                {
                  name: "Create Proforma Invoice",
                  to: "sales-and-invoicing/create-proforma-invoice",
                },
                {
                  name: "Create Quotation",
                  to: "sales-and-invoicing/create-quotation",
                },
              ]),
          collabOptions({ childRoutesName: "grandchildRoutes" }),
        ],
      },
      {
        name: "Quick Links",
        childRoutes: [
          {
            name: "Customers",
            to: "sales-and-invoicing/customer-list",
          },
          {
            name: "Subscribers",
            to: "sales-and-invoicing/subscriber-list",
          },
          {
            name: "Supply Analysis",
            to: "supply-analysis",
          },
          {
            name: "Sales and Invoicing",
            to: "sales-and-invoicing/invoice-list",
          },
          {
            name: "Make Invoice",
            to: "sales-and-invoicing/create-invoice",
          },
          {
            name: "POS",
            to: "sales-and-invoicing/pos",
          },
          {
            name: "Sales Inventory",
            to: "inventory-for-sales/inventory-entry",
          },
          {
            name: "Item Issuer",
            to: "inventory-for-sales/item-issuer",
          },

          {
            name: "GL Opening Balance",
            to: "journal-modification",
          },
          {
            name: "Supply Center",
            to: "supply-center",
          },
          {
            name: "Supply Analysis",
            to: "supply-analysis",
          },

          ...(isIronRod
            ? [
                ...(isAdmin || isCashier
                  ? [
                      {
                        name: "Approve Pending Invoice",
                        to: "approval",
                      },
                      {
                        name: "Approvals (Vendor Payment, Expenses, Journal etc)",
                        to: "approval-action",
                      },
                    ]
                  : []),
              ]
            : []),
          ...(isCement
            ? [
                {
                  name: "Raise LPO (Setup ATC)",
                  onClick: () => eventBus.dispatch("showSetupAtcModal", true),
                },
                {
                  name: "Load ATC",
                  to: "load-atc",
                },
                ...(isAdmin
                  ? [
                      {
                        name: "Price Settings",
                        to: "price-settings",
                      },
                      {
                        name: "Create Region",
                        onClick: () => eventBus.dispatch("showCreateRegionModal", true),
                      },
                      {
                        name: "Create Plant",
                        onClick: () => eventBus.dispatch("showCreatePlantModal", true),
                      },
                      {
                        name: "Create Depot",
                        onClick: () => eventBus.dispatch("showCreateDepotModal", true),
                      },
                      {
                        name: "Create Product or Service Category",
                        onClick: () => eventBus.dispatch("showCreateCategoryModal", true),
                      },
                      {
                        name: "Create Sub Category",
                        onClick: () => eventBus.dispatch("showCreateSubCategoryModal", true),
                      },
                    ]
                  : []),
              ]
            : []),
          ...(isTransportAndLogistics
            ? [
                {
                  name: "Driver Salary Center",
                  to: "driver-salary",
                },
              ]
            : []),
          {
            name: "Receive Inventory",
            to: "receive-inventory",
          },
          {
            name: "Expenses Entry",
            to: "expenses-entry-v2",
          },
          isAdmin || !(appSettings.isGigc && isCashier)
            ? {
                name: "Receive Payments",
                to: "post-payments",
              }
            : {},
          {
            name: "Reconcilation",
            to: "reconcilation/customer-ledger/Cash",
          },
          {
            name: "Due Payments",
            to: "sales-and-invoicing/due-invoices",
          },
          {
            name: "Delivery",
            to: "delivery",
          },
          {
            name: "Document Numbering",
            to: "document-number/system",
          },
          ...(generalSettings.Item_Type === "Service"
            ? [
                {
                  name: "Operations",
                  to: "operations/jobs/",
                },
                {
                  name: "QHSE",
                  to: "qhse/jobs/",
                },
              ]
            : []),
          generalSettings.enableDrumAccount
            ? {
                name: "Drums Account",
                grandchildRoutes: [
                  {
                    name: "Update Drum Account",
                    to: "drums/update-drum-account",
                  },
                  {
                    name: "Drum Account Sheet",
                    to: "drums/drum-account-sheet",
                  },
                ],
              }
            : {},
          {
            name: "Logistics",
            grandchildRoutes: [
              {
                name: "Loading Order",
                to: "loading-order",
              },
              {
                name: "Create Loading Order",
                to: "sales-and-invoicing/create-loading-order",
              },
              {
                name: "Pending Loading Order",
                to: "approval-pending-loading-order",
              },
              {
                name: "Product in Transit",
                to: "approval-product-on-transit",
              },
            ],
          },
          collabOptions({ childRoutesName: "grandchildRoutes" }),
        ],
      },
      {
        name: "Account Setup",
        childRoutes: [
          {
            name: "Chart of Account",
            to: "account-setup/chart-of-account",
          },
          {
            name: "Journal",
            to: "journal-entry",
          },
        ],
      },
      {
        name: "Production",
        childRoutes: [
          {
            name: "Plants",
            to: "Production/plants",
          },

          {
            name: "Bill of Materials",
            to: "production/temlates",
          },
        ],
      },
      {
        name: "Banking",
        childRoutes: [
          {
            name: "Banks",
            to: "banks",
          },
          {
            name: "Bank Account Analysis",
            to: "bank-account-analysis",
          },
          {
            name: "Cash Transfer and Lodgement",
            onClick: () => openAccountTransfer(),
          },
        ],
      },
      {
        name: "Reports",
        to: "reports",
        childRoutes: [...reportsNavItems],
      },
      {
        name: "Warehouse",
        to: "warehouse",
        childRoutes: [...wareHouseChildren],
      },
      {
        ...(generalSettings.Item_Type === "Service"
          ? { name: "Procurement" }
          : { name: "Vendor Management" }),
        childRoutes: [
          ...vendorManagement.filter(
            el => (isCement ? el.isCementNav : false) || typeof el.isCementNav === "undefined"
          ),
        ],
      },
      ...(generalSettings.Item_Type === "Service"
        ? [
            {
              name: "Maintenance",
              childRoutes: [...maintanceChildren],
            },
          ]
        : []),
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes:
          generalSettings.Item_Type === "Service"
            ? [
                {
                  name: "Request and Approvals",
                  to: "request",
                },
                {
                  name: "Fill a Form",
                  to: "fill-a-form",
                },
              ]
            : [
                {
                  name: "Request and Approvals",
                  to: "request",
                },
              ],
      },
    ],
    [
      //  authUser?.company,
      generalSettings.enableDrumAccount,
      isCement,
      isIronRod,
      isCashier,
      isAdmin,
      isTransportAndLogistics,
      generalSettings.Item_Type,
      reportsNavItems,
    ]
  );

  // Maintenance
  const maintenanceNavItems = useMemo(
    () => [
      {
        name: "Warehouse",
        to: "warehouse",
        childRoutes: [...wareHouseChildren],
      },
      {
        name: "Production",
        childRoutes: [
          {
            name: "Plants",
            to: "Production/plants",
          },

          {
            name: "Bill of Materials",
            to: "production/temlates",
          },
        ],
      },
      {
        name: "Maintenance",
        childRoutes: [...maintanceChildren],
      },
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Request and Approvals",
            to: "request",
          },
          {
            name: "Fill a Form",
            to: "fill-a-form",
          },
        ],
      },
      ...(appSettings.isHydroferric
        ? [
            {
              name: "Create Bill",
              to: "vendor/create-vendors-invoice-only",
            },
          ]
        : []),
      {
        name: "Human Resources",
        HR: true,
      },
      collabOptions(),
    ],
    [isMaintenance, authUser]
  );

  // Default
  const defaultNavItems = useMemo(
    () => [
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Request and Approvals",
            to: "request",
          },
          {
            name: "Fill a Form",
            to: "fill-a-form",
          },
        ],
      },
      {
        name: "Human Resources",
        HR: true,
      },
      collabOptions(),
    ],
    [isOperations, authUser]
  );

  // Government
  const governmentNavItems = useMemo(
    () => [
      {
        name: "Tools",
        childRoutes: [
          {
            name: "Return Invoice Item",
            to: "sales-and-invoicing/return-invoice-item",
          },
          {
            name: "Replace Invoice Item",
            to: "sales-and-invoicing/replace-invoice-item",
          },
          {
            name: "Edit Invoice Payment",
            to: "sales-and-invoicing/replace-payment",
          } /*  {
            name: "Edit Invoice",
            to: "sales-and-invoicing/edit-invoice",
          }, */,
          {
            name: "Delete Invoice",
            to: "sales-and-invoicing/delete-invoice",
          },
          {
            name: "Delete Payment",
            to: "sales-and-invoicing/delete-payment",
          },
          {
            name: `Create Proforma Invoice`,
            to: "sales-and-invoicing/create-proforma-invoice",
          },
          {
            name: "Credit/Debit Memo",
            to: "credit-debit-memo",
          },
          {
            name: "Item Conversion",
            to: "inventory-for-sales/item-conversion",
          },
          {
            name: "Refund Payment",
            to: "refund-payment",
          },
          {
            name: "Reimbursement",
            to: "reimbursement",
          },
          {
            name: "Analytics",
            to: "analytics",
          },
        ],
      },
      {
        name: "Quick Links",
        childRoutes: [
          {
            name: "Customers",
            to: "sales-and-invoicing/customer-list",
          },
          {
            name: "Subscribers",
            to: "sales-and-invoicing/subscriber-list",
          },
          {
            name: "Sales and Invoicing",
            to: "sales-and-invoicing/invoice-list",
          },
          {
            name: "Make Invoice",
            to: "sales-and-invoicing/create-invoice",
          },
          {
            name: "POS",
            to: "sales-and-invoicing/pos",
          },
          {
            name: "Inventory For Sales",
            to: "inventory-for-sales/inventory-entry",
          },
          {
            name: "Item Issuer",
            to: "inventory-for-sales/item-issuer",
          },
          {
            name: "Supply Center",
            to: "supply-center",
          },
          {
            name: "Supply Analysis",
            to: "supply-analysis",
          },
          {
            name: "Approve Pending Invoice",
            to: "approval",
          },
          {
            name: "Approvals (Vendor Payment, Expenses, Journal etc)",
            to: "approval-action",
          },
          {
            name: "Receive Inventory",
            to: "receive-inventory",
          },
          {
            name: "Expenses Entry",
            to: "expenses-entry-v2",
          },
          {
            name: "Receive Payments",
            to: "post-payments",
          },
          {
            name: "Reconcilation",
            to: "reconcilation/customer-ledger/Cash",
          },
          {
            name: "Due Payments",
            to: "sales-and-invoicing/due-invoices",
          },

          {
            name: "Document Numbering",
            to: "document-number/system",
          },
          ...(generalSettings.Item_Type === "Service"
            ? [
                {
                  name: "Operations",
                  to: "operations/jobs/",
                },
                {
                  name: "QHSE",
                  to: "qhse/jobs/",
                },
              ]
            : []),
          collabOptions({ childRoutesName: "grandchildRoutes" }),
        ],
      },
      {
        name: "Account Setup",
        childRoutes: [
          {
            name: "Chart of Account",
            to: "account-setup/chart-of-account",
          },
          {
            name: "Journal",
            to: "journal-entry",
          },
        ],
      },
      {
        name: "Banking",
        childRoutes: [
          {
            name: "Banks",
            to: "banks",
          },
          {
            name: "Bank Account Analysis",
            to: "bank-account-analysis",
          },
          {
            name: "Cash Transfer and Lodgement",
            onClick: () => openAccountTransfer(),
          },
        ],
      },
      {
        name: "Reports",
        to: "reports",
        childRoutes: [...reportsNavItems],
      },
      {
        name: "Warehouse",
        to: "warehouse",
        childRoutes: [...wareHouseChildren],
      },
      {
        ...(generalSettings.Item_Type === "Service"
          ? { name: "Procurement" }
          : { name: "Vendor Management" }),
        childRoutes: [
          ...vendorManagement.filter(
            el => (isCement ? el.isCementNav : false) || typeof el.isCementNav === "undefined"
          ),
        ],
      },
      {
        name: "Production",
        childRoutes: [
          {
            name: "Plants",
            to: "Production/plants",
          },

          {
            name: "Bill Of Materials",
            to: "production/temlates",
          },
        ],
      },
      ...(generalSettings.Item_Type === "Service"
        ? [
            {
              name: "Maintenance",
              childRoutes: [...maintanceChildren],
            },
          ]
        : []),
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes:
          generalSettings.Item_Type === "Service"
            ? [
                {
                  name: "Forms",
                  to: "forms/company",
                },
                {
                  name: "Request and Approvals",
                  to: "request",
                },
                {
                  name: "Fund Request Approval",
                  to:
                    "fund-request-approval?page=1&limit=40&q=&status=&type=Fund+Request&sortByDate=dateModified&FormID=&showAll=true",
                },
                {
                  name: "Fill a Form",
                  to: "fill-a-form",
                },
              ]
            : [
                {
                  name: "Request and Approvals",
                  to: "request",
                },
              ],
      },
    ],
    [
      //  authUser?.company,
      generalSettings.enableDrumAccount,
      isCement,
      isIronRod,
      isCashier,
      isAdmin,
      isTransportAndLogistics,
      generalSettings.Item_Type,
      reportsNavItems,
    ]
  );

  const { getRootRoles, resolveName } = useResolveRootRoles();
  const resolveNav = department => {
    department = toLower(department);
    const navItems =
      department === "admin" ||
      department === "out grower" ||
      !isEmpty(savedStaffRoles) ||
      !isEmpty(savedDeptRoles)
        ? items
        : ["store", "warehouse"].includes(department)
        ? storeUserNav
        : ["sales", "sales manager", "hr", "waiter"].includes(department)
        ? salesUserNav
        : department === "qhse"
        ? qhseNavItems
        : department === "operations"
        ? operationsNavItems
        : department === "procurement"
        ? procurementNavItems
        : department === "business development"
        ? buisinessDevelopmentNavItems
        : department === "document control"
        ? documentControlNavItems
        : ["cashier", "accountant"].includes(department)
        ? cashierNavItems
        : department === "maintenance"
        ? maintenanceNavItems
        : department === "government invexerp"
        ? governmentNavItems
        : department === "hospital management board" ||
          department === "primary healthcare center" ||
          department === "ministry of health"
        ? []
        : department === "supply and logistics"
        ? [
            {
              name: "Supply Center",
              to: "supply-center",
            },
            {
              name: "Supply Analysis",
              to: "supply-analysis",
            },
            { name: "Delivery", to: "delivery" },
          ]
        : defaultNavItems;

    //  console.log(cashierNavItems, navItems, department);

    return getRootRoles({
      parent: "",
      navItems,
    });
  };

  const switchCompany = async company => {
    //  console.log(company);
    try {
      setSmShow(true);
      // logout old company
      // ---------------------------------------------------------------------
      const res = await fetch(`${backendUrl}/api/auth/logout`, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        body: JSON.stringify({ Staff_ID: authUser.Staff_ID }),
        credentials: "include",
      });

      if (!res.ok) throw new Error();

      // HR Service
      let HRres = null;
      if (IS_HR) {
        HRres = await fetch(`${HRBackEndUrl}/users/switch-company`, {
          method: "POST",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          body: JSON.stringify({
            Staff_ID: authUser.Staff_ID,
            backendApi: company,
          }),
          credentials: "include",
        });

        if (!HRres.ok) throw new Error();

        // HR Service
        // if (HRres.ok) {
        //   const HRData = await HRres.json();
        //   setCookies(HRData);
        // }
      }

      // login new company
      //-------------------------------------------------------------------------
      const loginResponse = await fetch(`${company.url}/api/auth/authorize`, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        body: JSON.stringify({
          username: authUser.username,
          oldToken: authUser.token,
        }),
        credentials: "include",
      });
      if (!loginResponse.ok) {
        logout();
      }

      const { user } = await loginResponse.json();
      if (isEmpty(user)) {
        toast.error("User not found in company: Try again");
        logout();
      }

      setUser({
        ...user,
        timestamp: String(Date.now()),
        company: company.name,
      });
    } catch (err) {
      console.log(err);
      toast.error("Unable switch company, Please try again");
    } finally {
      setSmShow(false);
    }
  };

  eventBus.useCustomEventListener("LOGOUT", options => {
    logoutUser(options);
  });

  // useEffect(() => {
  //   setIsShaking(true);
  //   const shakeInterval = setInterval(() => {
  //     setIsShaking(true);
  //     setTimeout(() => {
  //       setIsShaking(false);
  //     }, 10000);
  //   }, 30000);

  //   return () => clearInterval(shakeInterval);
  // }, []);
  const hideVerifyEmailModal = () => {
    setShowVerifyEmail(false);
  };

  const hideUpdateEmailModal = () => {
    setShowUpdateEmail(false);
  };

  const showUpdateEmailModal = () => {
    // setIsShaking(false);
    if (!authUser?.isEmailVerified && !authUser?.email) {
      setShowUpdateEmail(true);
    }
    if (authUser?.email && !authUser?.isEmailVerified) {
      setShowVerifyEmail(true);
    }
  };

  // Use Paid for only form
  // const isRequisition = useMemo(() => location?.pathname.startsWith("/requisition"), [location?.pathname])
  const isRequisition = useMemo(() => authUser?.invexVersion === "InvexForms", [
    authUser?.invexVersion,
  ]);
  const isInvoicing = useMemo(() => authUser?.invexVersion === "InvexInvoicing", [
    authUser?.invexVersion,
  ]);

  return (
    <>
      {showCreateTicket && <CreateTicket show={showCreateTicket} setShow={setShowCreateTicket} />}
      <VerifyEmailModal
        show={showVerifyEmail}
        onHide={hideVerifyEmailModal}
        email={authUser.email}
        name={authUser.Name}
        showUpdateEmailModal={showUpdateEmailModal}
      />
      <UpdateEmailModal show={showUpdateEmail} onHide={hideUpdateEmailModal} />
      <nav className={`NavBar`}>
        <div className={`d-flex align-items-center`}>
          {isRequisition && (
            <>
              <RequisitionNavBar
                links={items}
                switchCompany={switchCompany}
                resolveName={resolveName}
              />
            </>
          )}
          {/* {isInvoicing && (
            <>
              <InvoicingNavBar
                links={items}
                switchCompany={switchCompany}
                resolveName={resolveName}
              />
            </>
          )} */}

          <NavLink
            to={!isAccountExpired ? "/dashboard" : "/payment-and-billing"}
            className="d-flex align-items-center"
          >
            <div className="home-icon d-none">
              <HomeIcon />
            </div>
            <Logo className="logo" />
          </NavLink>

          <ul
            ref={navHolder}
            className={`${navOpen ? "open" : "closed"} ${
              isRequisition || isInvoicing ? "d-none" : ""
            }`}
          >
            {!isAccountExpired &&
              resolveNav(authUser.Department).map((item, index) => (
                <li key={index}>
                  {!item.childRoutes ? (
                    item?.switchUser ? (
                      <Link className=" btn fw-normal" onClick={() => setShowSwitchUser(true)}>
                        {item.name}
                      </Link>
                    ) : item.HR ? (
                      IS_HR ? (
                        <a
                          href={HRFrontEndUrl}
                          className={` ${
                            location.pathname.startsWith(`/${item.to}`) ? "active fw-6" : ""
                          }`}
                        >
                          {resolveName(item.name)}
                        </a>
                      ) : (
                        <Link
                          to="/no-hr"
                          className={`btn fw-normal ${
                            location.pathname.startsWith(`/${item.to}`) ? "fw-6" : ""
                          }`}
                        >
                          {resolveName(item.name)}
                        </Link>
                      )
                    ) : (
                      <Link
                        to={item?.to ? item.to : ""}
                        className={`btn fw-normal ${
                          location.pathname.startsWith(`/${item.to}`) ? "fw-6" : ""
                        }`}
                      >
                        {resolveName(item.name)}
                      </Link>
                    )
                  ) : item.name === "Quick Links" ? (
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="white"
                        bsPrefix={`toggle ${
                          location.pathname.startsWith(`/${item.to}`) ? "active fw-6" : ""
                        }`}
                      >
                        {resolveName(item.name)}
                      </Dropdown.Toggle>
                      {/* "Large Quick Links" */}
                      {isAdmin && generalSettings?.largeQuickLink ? (
                        <QuickLinkComponent
                          items={item.childRoutes}
                          isIronRod={isIronRod}
                          isAdmin={isAdmin}
                          isCashier={isCashier}
                          isCement={isCement}
                          isTransportAndLogistics={isTransportAndLogistics}
                          isGigc={appSettings.isGigc}
                          Item_Type={generalSettings.Item_Type}
                          enableDrumAccount={generalSettings.enableDrumAccount}
                        />
                      ) : (
                        <Dropdown.Menu
                          popperConfig={{
                            strategy: "fixed",
                          }}
                          renderOnMount
                          className={"overflow-y-auto"}
                        >
                          {item.childRoutes.map((el, index) => (
                            <Fragment key={index}>
                              {el.to ? (
                                <Dropdown.Item as="div">
                                  <Link
                                    to={
                                      !el.to.includes("http")
                                        ? `${item.to ? `/${item.to}` : ""}/${el.to}`
                                        : el.to
                                    }
                                    target={el.to.includes("http") ? "_blank" : ""}
                                  >
                                    {truncate(resolveName(el.name), {
                                      length: 40,
                                    })}{" "}
                                  </Link>
                                </Dropdown.Item>
                              ) : el.onClick ? (
                                <Dropdown.Item onClick={() => el.onClick()} as="button">
                                  {resolveName(el.name)}{" "}
                                  <span className="shortcut">{el?.shortcut}</span>
                                </Dropdown.Item>
                              ) : el.grandchildRoutes ? (
                                <>
                                  <OverflowDropdown
                                    toggle={
                                      <Dropdown.Toggle
                                        variant="white"
                                        bsPrefix="w-100 text-left d-flex justify-content-between"
                                        style={{ textAlign: "left" }}
                                      >
                                        {truncate(resolveName(el.name), {
                                          length: 40,
                                        })}{" "}
                                        <span>&raquo;</span>
                                      </Dropdown.Toggle>
                                    }
                                    menu={
                                      <Dropdown.Menu>
                                        {el.grandchildRoutes.map(grandEl => (
                                          <Dropdown.Item as="div" key={grandEl.name}>
                                            <Link
                                              to={
                                                !grandEl.to.includes("http")
                                                  ? `/${grandEl.to}`
                                                  : grandEl.to
                                              }
                                              target={grandEl.to.includes("http") ? "_blank" : ""}
                                            >
                                              {resolveName(grandEl.name)}
                                            </Link>
                                          </Dropdown.Item>
                                        ))}
                                      </Dropdown.Menu>
                                    }
                                  />

                                  {/*   <div className="position-relative submenu">
                                    <button
                                      className="dropdown-item justify-content-between submenu-toggle px-3"
                                      href="#"
                                    >
                                      {resolveName(el.name)}{" "}
                                      <span>&raquo;</span>
                                    </button>
                                    {el.grandchildRoutes && (
                                      <ul className="dropdown-menu dropdown-submenu text-left">
                                        {el.grandchildRoutes.map((grandEl) => (
                                          <li
                                            className="w-100"
                                            key={grandEl.name}
                                          >
                                            <Dropdown.Item as="div">
                                              <Link to={`/${grandEl.to}`}>
                                                {resolveName(grandEl.name)}
                                              </Link>
                                            </Dropdown.Item>
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                  </div> */}
                                </>
                              ) : null}
                            </Fragment>
                          ))}
                        </Dropdown.Menu>
                      )}
                    </Dropdown>
                  ) : (
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="white"
                        bsPrefix={`toggle ${
                          location.pathname.startsWith(`/${item.to}`) ? "active fw-6" : ""
                        }`}
                      >
                        {resolveName(item.name)}
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                        className={"overflow-y-auto"}
                      >
                        {item.childRoutes.map((el, index) => (
                          <Fragment key={index}>
                            {el.to ? (
                              <Dropdown.Item as="div">
                                <Link
                                  to={
                                    !el.to.includes("http")
                                      ? `${item.to ? `/${item.to}` : ""}/${el.to}`
                                      : el.to
                                  }
                                  target={el.to.includes("http") ? "_blank" : ""}
                                >
                                  {truncate(resolveName(el.name), {
                                    length: 40,
                                  })}{" "}
                                </Link>
                              </Dropdown.Item>
                            ) : el.onClick ? (
                              <Dropdown.Item onClick={() => el.onClick()} as="button">
                                {resolveName(el.name)}{" "}
                                <span className="shortcut">{el?.shortcut}</span>
                              </Dropdown.Item>
                            ) : el.grandchildRoutes ? (
                              <>
                                <OverflowDropdown
                                  toggle={
                                    <Dropdown.Toggle
                                      variant="white"
                                      bsPrefix="w-100 text-left d-flex justify-content-between dropdown-item"
                                      style={{ textAlign: "left" }}
                                    >
                                      {truncate(resolveName(el.name), {
                                        length: 40,
                                      })}{" "}
                                      <span>&raquo;</span>
                                    </Dropdown.Toggle>
                                  }
                                  menu={
                                    <Dropdown.Menu>
                                      {el.grandchildRoutes.map(grandEl => (
                                        <Dropdown.Item as="div" key={grandEl.name}>
                                          <Link
                                            to={
                                              !grandEl.to.includes("http")
                                                ? `/${grandEl.to}`
                                                : grandEl.to
                                            }
                                            target={grandEl.to.includes("http") ? "_blank" : ""}
                                          >
                                            {resolveName(grandEl.name)}
                                          </Link>
                                        </Dropdown.Item>
                                      ))}
                                    </Dropdown.Menu>
                                  }
                                />

                                {/*   <div className="position-relative submenu">
                                    <button
                                      className="dropdown-item justify-content-between submenu-toggle px-3"
                                      href="#"
                                    >
                                      {resolveName(el.name)}{" "}
                                      <span>&raquo;</span>
                                    </button>
                                    {el.grandchildRoutes && (
                                      <ul className="dropdown-menu dropdown-submenu text-left">
                                        {el.grandchildRoutes.map((grandEl) => (
                                          <li
                                            className="w-100"
                                            key={grandEl.name}
                                          >
                                            <Dropdown.Item as="div">
                                              <Link to={`/${grandEl.to}`}>
                                                {resolveName(grandEl.name)}
                                              </Link>
                                            </Dropdown.Item>
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                  </div> */}
                              </>
                            ) : null}
                          </Fragment>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </li>
              ))}

            {isAdmin ? (
              <li>
                <Dropdown>
                  <Dropdown.Toggle variant="white" bsPrefix={`toggle`}>
                    Switch Company
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    popperConfig={{
                      strategy: "fixed",
                    }}
                    renderOnMount
                  >
                    {backendApis
                      .filter(el => !el.viewOnly)
                      .map(el => (
                        <Dropdown.Item
                          key={el.name}
                          as="button"
                          className={`${authUser?.company === el.name ? "active" : ""}`}
                          onClick={() => switchCompany(el)}
                        >
                          {el.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            ) : null}
          </ul>
        </div>

        <div className={`d-flex align-items-center`}>
          {isRequisition && (
            <>
              {IS_HR ? (
                <a href={HRFrontEndUrl} target="_blank" className="px-3">
                  Human Resources
                </a>
              ) : null}
            </>
          )}

          {!Boolean(data?.data?.loggedInUser?.isEmailVerified) && (
            <span
              className=" fw-bold text-danger"
              style={{ cursor: "pointer" }}
              onClick={showUpdateEmailModal}
            >
              Verify email
            </span>
          )}

          {/*   <ChevronDownIcon style={{ color: "#0A4A95" }} /> */}
          <div className="mx-3">
            <Notification
              backendUrl={backendUrl}
              Staff_ID={authUser?.Staff_ID}
              sound={authUser?.sound}
            />
          </div>

          <Dropdown>
            <Dropdown.Toggle variant="white" className="btn d-flex border-0 align-items-center p-1">
              <Avatar
                className="avatar"
                pictureUrl={authUser?.picture}
                name={authUser?.Name}
                company={authUser?.company}
                department={authUser?.Department}
                userId={authUser?.Staff_ID}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu
              popperConfig={{
                strategy: "fixed",
              }}
              renderOnMount
            >
              <UserProfilePics>
                <Avatar
                  pictureUrl={authUser?.picture}
                  name={authUser?.Name}
                  company={authUser?.company}
                  style={{ width: "5rem", height: "5rem" }}
                  rounded={true}
                  userId={authUser?.Staff_ID}
                />

                <p className="pt-2 text-center">
                  <span className="fw-bold">{authUser?.Name}</span>
                  <br />
                  <span title={authUser?.email}>
                    {truncate(authUser?.email, {
                      length: 30,
                    })}
                  </span>
                  <br />
                  <span className="fw-5">{authUser?.Department}</span>
                </p>
              </UserProfilePics>

              {!isAccountExpired && (
                <Dropdown.Item
                  className="text-center d-flex justify-content-center text-primary"
                  as="button"
                  onClick={() => setShowProfile(true)}
                >
                  Update Profile
                </Dropdown.Item>
              )}

              <Dropdown.Divider />

              {isAdmin && !isAccountExpired && (
                <>
                  <Dropdown.Item as="button">
                    <Link to="/dashboard/user-managemnt">User Management</Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="button">
                    <Link to="/dashboard/company-information-settings">
                      Company Information Settings
                    </Link>
                  </Dropdown.Item>

                  {isAdminPrivilegedToSeeSettings && (
                    <Dropdown.Item as="button" onClick={() => setShowGeneralSettingsModal(true)}>
                      Settings
                    </Dropdown.Item>
                  )}
                </>
              )}

              {generalSettings.invoicingType === "Point of Sale" && (
                <Dropdown.Item as="button" onClick={() => setShowSwitchUser(true)}>
                  Switch User
                </Dropdown.Item>
              )}

              {isAdmin && (
                <Dropdown.Item as="button">
                  <Link to="/payment-and-billing">Payments & Billing</Link>
                </Dropdown.Item>
              )}

              {/*  {isAdmin && (
              <>
                <Dropdown.Item
                  as="button"
                  onClick={() => setShowRolesPriviledgesModal(true)}
                >
                  Roles & Priviledge
                </Dropdown.Item>
              </>
            )} */}
              <Dropdown.Item as="button">
                {services.frontendUrl === `http://localhost:3000` ? (
                  <Link to={`/user-feedBack?UserName=${authUser.Name}&Useremail=${authUser.email}`}>
                    Feedback
                  </Link>
                ) : (
                  <a
                    href={`https://feedback.invexonline.com/user-feedBack?UserName=${
                      authUser.Name
                    }&Useremail=${authUser.email}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Feedback
                  </a>
                )}
              </Dropdown.Item>
              {!isAccountExpired && (
                <Dropdown.Item
                  // className="d-flex justify-content-center "
                  onClick={() => setShowCreateTicket(true)}
                >
                  Create Ticket
                </Dropdown.Item>
              )}
              {/*  <Dropdown.Item
              as="button"
              onClick={() => setShowServiceSettingsModal(true)}
            >
              Service Settings
            </Dropdown.Item> */}
              <Dropdown.Item as="button" onClick={() => logoutUser()}>
                Logout
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item as="button" onClick={checkCacheStatus}>
                Version {process.env?.REACT_APP_VERSION}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <button onClick={() => toggleNavOpen()} className="btn p-1 d-md-none">
            <HamburgerIcon />
          </button>
        </div>

        {showProfile && (
          <UpdateProfileModal show={showProfile} onHide={() => setShowProfile(false)} />
        )}
        {isAccountTransferOpen && (
          <AccountTransferModal setShowAccountTransferModal={setIsAccountTransferOpen} />
        )}
        {showGeneralSettingsModal && (
          <GeneralSettingsModal
            showGeneralSettingsModal={showGeneralSettingsModal}
            setShowGeneralSettingsModal={setShowGeneralSettingsModal}
          />
        )}
        {showRolesPriviledgesModal && (
          <RolesPriviledgesModal
            showRolesPriviledgesModal={showRolesPriviledgesModal}
            setShowRolesPriviledgesModal={setShowRolesPriviledgesModal}
          />
        )}

        {showRootRolesPriviledgesModal && (
          <RootRolesPriviledgesModal
            showRolesPriviledgesModal={showRootRolesPriviledgesModal}
            setShowRolesPriviledgesModal={setShowRootRolesPriviledgesModal}
          />
        )}

        {showServiceSettingsModal && (
          <ServiceSettingsModal
            showServiceSettingsModal={showServiceSettingsModal}
            setShowServiceSettingsModal={setShowServiceSettingsModal}
          />
        )}
        <ModalLoader show={smShow} />
        {/*  Regions, plants cat, sub cats  */}
        <CementModals />
      </nav>

      {showSwitchUser && (
        <UserLginModal show={showSwitchUser} setShowSwitchUser={setShowSwitchUser} />
      )}

      {showGlobalItemSearchModal && (
        <GlobalItemSearchModal
          show={showGlobalItemSearchModal}
          setShow={setShowGlobalItemSearchModal}
        />
      )}
    </>
  );
}
