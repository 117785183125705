import { endOfDay, startOfDay } from "date-fns";
import { compareAsc } from "date-fns/esm";
import { memo, useMemo } from "react";
import { Form } from "react-bootstrap";

const ReconcilationCheckbox = memo(
  ({ Post_Time, reconciled, reconciledSuccessText = "Cleared", ...props }) => {
    const isOldRecord = useMemo(() => {
      return Post_Time
        ? compareAsc(new Date(startOfDay(new Date())), new Date(Post_Time)) === 1 && !props?.checked
        : true;
    }, [Post_Time, props?.checked]);

    return (
      <div className="d-flex gap-3 fw-bold">
        <Form.Check type="checkbox" {...props} isInvalid={isOldRecord} />

        {reconciled === null ? (
          <span>Pending</span>
        ) : Boolean(reconciled) ? (
          <span className={reconciledSuccessText === "Cleared" ? "text-success" : ""}>
            {reconciledSuccessText}
          </span>
        ) : (
          <span>Pending</span>
        )}
      </div>
    );
  }
);

export default ReconcilationCheckbox;
